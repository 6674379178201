import React, { useState, useEffect, useCallback } from 'react';
import { User, Search, Users, Building2 } from 'lucide-react';
import { supabase } from '../../supabaseClient';
import styles from './MessageInbox.module.css';

const MessagePreview = ({ 
  title, 
  lastMessage, 
  time, 
  unreadCount, 
  type,
  participants,
  onClick 
}) => (
  <div className={styles.messageContainer} onClick={onClick}>
    <div className={styles.messageBackground} />
    <div className={styles.userAvatar}>
      {type === 'group' ? (
        <Users size={24} />
      ) : type === 'support' ? (
        <Building2 size={24} />
      ) : (
        <User size={24} />
      )}
    </div>
    <div className={styles.messageContent}>
      <div className={styles.headerRow}>
        <p className={styles.userName}>{title}</p>
        {type === 'group' && (
          <span className={styles.participantCount}>
            {participants.length} members
          </span>
        )}
      </div>
      <p className={styles.messageText}>
        {lastMessage?.sender_name && type !== 'direct' && (
          <span className={styles.senderPrefix}>
            {lastMessage.sender_name}:{' '}
          </span>
        )}
        {lastMessage?.content}
      </p>
    </div>
    <div className={styles.messageInfo}>
      <span className={styles.messageTime}>{time}</span>
      {unreadCount > 0 && (
        <div className={styles.unreadBadge}>
          <span>{unreadCount}</span>
        </div>
      )}
    </div>
  </div>
);

const MessageInbox = ({ onConversationSelect }) => {
  const [conversations, setConversations] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchConversations = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();

      const { data, error } = await supabase
        .from('conversation_participants')
        .select(`
          conversation_id,
          role,
          last_read_at,
          on_behalf_of_non_profit_id,
          conversations!inner (
            id,
            type,
            title,
            non_profit_id,
            created_at,
            last_message_at,
            messages!messages_conversation_id_fkey (
              id,
              content,
              created_at,
              sender_id,
              on_behalf_of_non_profit_id,
              message_type,
              Users!messages_sender_id_fkey (
                first_name,
                last_name
              )
            ),
            conversation_participants (
              user_id,
              role,
              Users (
                first_name,
                last_name
              )
            )
          )
        `)
        .eq('user_id', user.id)
        .order('conversations(last_message_at)', { ascending: false });

      if (error) throw error;

      const formattedConversations = data.map(participant => {
        const conv = participant.conversations;
        const messages = conv.messages || [];
        const lastMessage = messages.length > 0 ? 
          messages.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0] 
          : null;
        
        const unreadCount = messages.filter(msg => 
          new Date(msg.created_at) > new Date(participant.last_read_at || conv.created_at) &&
          msg.sender_id !== user.id
        ).length;

        const allParticipants = conv.conversation_participants || [];
        const otherParticipants = allParticipants.filter(p => p.user_id !== user.id);

        let title = conv.title;
        if (!title) {
          if (conv.type === 'direct') {
            const otherUser = otherParticipants[0]?.Users;
            title = otherUser ? `${otherUser.first_name} ${otherUser.last_name}` : 'Unknown User';
          } else if (conv.type === 'support') {
            title = 'Support Chat';
          }
        }

        return {
          id: conv.id,
          type: conv.type,
          title,
          lastMessage: lastMessage ? {
            content: lastMessage.content,
            sender_name: lastMessage.Users ? 
              `${lastMessage.Users.first_name} ${lastMessage.Users.last_name}` : 
              'Unknown User',
            type: lastMessage.message_type,
            on_behalf_of_non_profit: !!lastMessage.on_behalf_of_non_profit_id
          } : null,
          time: lastMessage ? new Date(lastMessage.created_at).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
          }) : '',
          unreadCount,
          participants: allParticipants,
          userRole: participant.role
        };
      });

      setConversations(formattedConversations);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchConversations();

    const messageSubscription = supabase
      .channel('messages_channel')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'messages'
      }, () => {
        fetchConversations();
      })
      .subscribe();

    return () => {
      messageSubscription.unsubscribe();
    };
  }, [fetchConversations]);

  const filteredConversations = conversations
    .filter(conv => {
      if (selectedFilter === 'all') return true;
      return conv.type === selectedFilter;
    })
    .filter(conv => {
      if (!searchTerm) return true;
      return (
        conv.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        conv.lastMessage?.content.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });

  return (
    <div className={styles.inbox}>
      <div className={styles.inboxHeader}>
        <h2 className={styles.inboxTitle}>Messages</h2>
        <div className={styles.searchBar}>
          <Search size={20} />
          <input 
            type="text" 
            placeholder="Search Messages" 
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={styles.searchInput}
          />
        </div>
        <div className={styles.filters}>
          <button 
            className={`${styles.filter} ${selectedFilter === 'all' ? styles.active : ''}`}
            onClick={() => setSelectedFilter('all')}
          >
            All
          </button>
          <button 
            className={`${styles.filter} ${selectedFilter === 'direct' ? styles.active : ''}`}
            onClick={() => setSelectedFilter('direct')}
          >
            Direct Messages
          </button>
          <button 
            className={`${styles.filter} ${selectedFilter === 'support' ? styles.active : ''}`}
            onClick={() => setSelectedFilter('support')}
          >
            Support
          </button>
          <button 
            className={`${styles.filter} ${selectedFilter === 'group' ? styles.active : ''}`}
            onClick={() => setSelectedFilter('group')}
          >
            Group Chats
          </button>
        </div>
      </div>

      <div className={styles.messageList}>
        {loading ? (
          <div className={styles.loading}>Loading conversations...</div>
        ) : filteredConversations.length === 0 ? (
          <div className={styles.noMessages}>No messages found</div>
        ) : (
          filteredConversations.map((conv) => (
            <MessagePreview 
              key={conv.id} 
              {...conv} 
              onClick={() => onConversationSelect(conv)}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default MessageInbox;