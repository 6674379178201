// src/components/SignIn.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import styles from './SignIn.module.css';

const SignIn = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: credentials.email,
        password: credentials.password,
      });

      if (error) throw error;

      const { data: userData, error: userError } = await supabase
        .from('Users')
        .select('*')
        .eq('id', data.user.id)
        .single();

      if (userError) throw userError;

      if (userData.is_non_profit) {
        navigate('/nonprofit-dashboard');
      } else {
        navigate('/volunteer-dashboard');
      }
    } catch (error) {
      console.error('Error during sign in:', error);
      setError('Invalid email or password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.signInPage}>
      <div className={styles.topNavigation}>
        <img className={styles.logo} src="/WORDMARK_ALTRUENCE.svg" alt="Altruence" />
      </div>

      <div className={styles.formContainer}>
        <div className={styles.formCard}>
          <h2 className={styles.title}>Sign In</h2>
          {error && <p className={styles.error}>{error}</p>}
          
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <label className={styles.label}>Email</label>
              <input
                type="email"
                name="email"
                className={styles.input}
                onChange={handleChange}
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.label}>Password</label>
              <input
                type="password"
                name="password"
                className={styles.input}
                onChange={handleChange}
                required
              />
            </div>

            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={isLoading}
            >
              {isLoading ? 'Signing In...' : 'Sign In'}
            </button>
          </form>

          <div className={styles.footer}>
            <p>Don't have an account?</p>
            <div className={styles.signupOptions}>
              <button 
                onClick={() => navigate('/volunteer-signup')}
                className={styles.optionButton}
              >
                Sign up as Volunteer
              </button>
              <button 
                onClick={() => navigate('/nonprofit-onboarding')}
                className={styles.optionButton}
              >
                Sign up as Non-Profit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;