import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { Search, Filter, UserCircle, Download } from 'lucide-react';
import styles from './VolunteersListPage.module.css';

const VolunteerListPage = () => {
  const [volunteers, setVolunteers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');

  useEffect(() => {
    fetchVolunteers();
  }, []);

  const fetchVolunteers = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data: nonProfit } = await supabase
        .from('non_profits')
        .select('id')
        .eq('user_id', user.id)
        .single();

      const { data: volunteerData, error } = await supabase
        .from('event_volunteers')
        .select(`
          *,
          Users (
            id,
            first_name,
            last_name,
            email,
            phone_number
          ),
          events (
            title,
            start_time,
            end_time
          )
        `)
        .eq('events.non_profit_id', nonProfit.id);

      if (error) throw error;
      setVolunteers(volunteerData);
    } catch (error) {
      console.error('Error fetching volunteers:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredVolunteers = volunteers.filter(volunteer => {
    const matchesSearch = 
      volunteer.Users.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      volunteer.Users.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      volunteer.Users.email.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = statusFilter === 'all' || volunteer.status === statusFilter;
    
    return matchesSearch && matchesStatus;
  });

  const downloadCSV = () => {
    const headers = ['First Name', 'Last Name', 'Email', 'Phone', 'Event', 'Status', 'Start Date'];
    const csvData = filteredVolunteers.map(volunteer => [
      volunteer.Users.first_name,
      volunteer.Users.last_name,
      volunteer.Users.email,
      volunteer.Users.phone_number || 'N/A',
      volunteer.events?.title || 'None',
      volunteer.status,
      volunteer.events ? new Date(volunteer.events.start_time).toLocaleDateString() : 'N/A'
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'volunteers.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={styles.page}>
      {loading ? (
        <div className={styles.loading}>Loading...</div>
      ) : (
        <>
          <div className={styles.header}>
            <h1>Volunteers List</h1>
            <div className={styles.actions}>
              <div className={styles.filters}>
                <div className={styles.searchBox}>
                  <Search size={20} />
                  <input
                    type="text"
                    placeholder="Search volunteers..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className={styles.statusFilter}>
                  <Filter size={20} />
                  <select
                    value={statusFilter}
                    onChange={(e) => setStatusFilter(e.target.value)}
                  >
                    <option value="all">All Status</option>
                    <option value="pending">Pending</option>
                    <option value="accepted">Accepted</option>
                    <option value="confirmed">Confirmed</option>
                    <option value="denied">Denied</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
              </div>
              <button onClick={downloadCSV} className={styles.downloadButton}>
                <Download size={20} />
                Export CSV
              </button>
            </div>
          </div>

          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Volunteer</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Current Event</th>
                  <th>Start Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredVolunteers.map(volunteer => (
                  <tr key={volunteer.id}>
                    <td className={styles.volunteerCell}>
                      <UserCircle size={32} className={styles.avatar} />
                      <span>{volunteer.Users.first_name} {volunteer.Users.last_name}</span>
                    </td>
                    <td>{volunteer.Users.email}</td>
                    <td>{volunteer.Users.phone_number || 'N/A'}</td>
                    <td>{volunteer.events?.title || 'None'}</td>
                    <td>
                      {volunteer.events 
                        ? new Date(volunteer.events.start_time).toLocaleDateString()
                        : 'N/A'
                      }
                    </td>
                    <td>
                      <span className={`${styles.status} ${styles[volunteer.status]}`}>
                        {volunteer.status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default VolunteerListPage; 