import React from 'react';
import styles from './EventsPages.module.css';

const EventTemplatesPage = () => {
  return (
    <div className={styles.page}>
      <h1>Event Templates</h1>
      {/* Content will be implemented later */}
    </div>
  );
};

export default EventTemplatesPage; 