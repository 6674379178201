import React, { useState } from 'react';
import styles from './UserRegistration.module.css';

const UserRegistration = ({ onNext, goToStep }) => {
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(userData);
  };

  return (
    <div className={styles.registrationPage}>
      <div className={styles.topNavigation}>
        <div className={styles.progressIndicator}>
          <div className={styles.progressStep} onClick={() => goToStep(1)}>
            <div className={styles.stepCurrent}>1</div>
            <span>Account</span>
          </div>
          <div className={styles.progressLine} />
          <div className={styles.progressStep} onClick={() => goToStep(2)}>
            <div className={styles.stepPending}>2</div>
            <span>Organization</span>
          </div>
          <div className={styles.progressLine} />
          <div className={styles.progressStep} onClick={() => goToStep(3)}>
            <div className={styles.stepPending}>3</div>
            <span>Review</span>
          </div>
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Create Your Account</h2>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>First Name</label>
              <input
                type="text"
                name="firstName"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your first name"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Last Name</label>
              <input
                type="text"
                name="lastName"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your last name"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Email</label>
              <input
                type="email"
                name="email"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Password</label>
              <input
                type="password"
                name="password"
                className={styles.input}
                onChange={handleChange}
                placeholder="Create a password"
                required
              />
            </div>

            <button type="submit" className={styles.submitButton}>
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserRegistration; 