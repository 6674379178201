import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../supabaseClient';
import styles from './Calendar.module.css';

const Calendar = ({ nonProfitId, onDayClick }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  const fetchEvents = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('events')
        .select(`
          id,
          title,
          start_time,
          max_volunteers
        `)
        .eq('non_profit_id', nonProfitId)
        .gte('start_time', new Date(currentDate.getFullYear(), currentDate.getMonth(), 1).toISOString())
        .lte('start_time', new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).toISOString());

      if (error) throw error;
      
      const formattedEvents = data.map(event => ({
        title: event.title,
        date: event.start_time,
        volunteers: event.max_volunteers
      }));
      
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }, [nonProfitId, currentDate]);

  useEffect(() => {
    if (nonProfitId) {
      fetchEvents();
    }
  }, [nonProfitId, currentDate, fetchEvents]);

  const getDaysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const getFirstDayOfMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  
  const navigateMonth = (direction) => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + direction, 1));
  };

  const renderCalendarDays = () => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = getFirstDayOfMonth(currentDate);
    const days = [];

    // Add blank spaces for days before the first day of the month
    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`blank-${i}`} className={styles.blank} />);
    }

    // Add the days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const dayEvents = events.filter(event => {
        const eventDate = new Date(event.date);
        return eventDate.getDate() === day && 
               eventDate.getMonth() === date.getMonth() && 
               eventDate.getFullYear() === date.getFullYear();
      });

      days.push(
        <div 
          key={day} 
          className={`${styles.day} ${dayEvents.length > 0 ? styles.hasEvent : ''}`}
          onClick={() => dayEvents.length > 0 && onDayClick && onDayClick(date)}
          style={{ cursor: dayEvents.length > 0 && onDayClick ? 'pointer' : 'default' }}
        >
          <span className={styles.dayNumber}>{day}</span>
          {dayEvents.map((event, index) => (
            <div key={index} className={styles.eventIndicator}>
              <div className={styles.eventTitle}>{event.title}</div>
              <div className={styles.eventVolunteers}>{event.volunteers} volunteers</div>
            </div>
          ))}
        </div>
      );
    }

    return days;
  };

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>
        <h2 className={styles.monthYear}>
          {monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}
        </h2>
        <div className={styles.navigation}>
          <button className={styles.navButton} onClick={() => navigateMonth(-1)}>
            ←
          </button>
          <button className={styles.navButton} onClick={() => navigateMonth(1)}>
            →
          </button>
        </div>
      </div>
      <div className={styles.weekdays}>
        {weekDays.map(day => (
          <div key={day} className={styles.weekday}>{day}</div>
        ))}
      </div>
      <div className={styles.days}>
        {renderCalendarDays()}
      </div>
    </div>
  );
};

export default Calendar;