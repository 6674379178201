import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import styles from './VolunteerApplicationsPage.module.css';
import { Search, Filter } from 'lucide-react';

const VolunteerApplicationsPage = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');

  useEffect(() => {
    fetchApplications();
  }, []);

  const fetchApplications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data: nonProfit } = await supabase
        .from('non_profits')
        .select('id')
        .eq('user_id', user.id)
        .single();

      const { data, error } = await supabase
        .from('event_volunteers')
        .select(`
          *,
          Users (
            id,
            first_name,
            last_name,
            email,
            phone_number
          ),
          events (
            title,
            start_time
          )
        `)
        .eq('events.non_profit_id', nonProfit.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setApplications(data);
    } catch (error) {
      console.error('Error fetching applications:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.page}>
      {loading ? (
        <div className={styles.loading}>Loading...</div>
      ) : (
        <>
          <div className={styles.header}>
            <h1>Volunteer Applications</h1>
            <div className={styles.filters}>
              <div className={styles.searchBox}>
                <Search size={20} />
                <input
                  type="text"
                  placeholder="Search applications..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className={styles.statusFilter}>
                <Filter size={20} />
                <select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="all">All Status</option>
                  <option value="pending">Pending</option>
                  <option value="accepted">Accepted</option>
                  <option value="denied">Denied</option>
                </select>
              </div>
            </div>
          </div>

          <div className={styles.applicationsGrid}>
            {applications.map(application => (
              <div key={application.id} className={styles.applicationCard}>
                <div className={styles.cardHeader}>
                  <h3>{application.Users.first_name} {application.Users.last_name}</h3>
                  <span className={styles.status}>{application.status}</span>
                </div>
                <div className={styles.cardContent}>
                  <p><strong>Event:</strong> {application.events.title}</p>
                  <p><strong>Applied:</strong> {new Date(application.created_at).toLocaleDateString()}</p>
                  <p><strong>Email:</strong> {application.Users.email}</p>
                  <p><strong>Phone:</strong> {application.Users.phone_number}</p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default VolunteerApplicationsPage; 