import React, { useState, useEffect, useCallback } from 'react';
import styles from './NonProfitDashboard.module.css';
import Calendar from '../components/dashboard/Calendar';
import TimeframeSelect from '../components/dashboard/TimeframeSelect';
import MessageNotifications from '../components/dashboard/MessageNotifications';
import { useAuth } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const NonProfitDashboard = () => {
  const { user, nonProfitData, loading } = useAuth();
  const [timeframe, setTimeframe] = useState('month');
  const [stats, setStats] = useState({
    totalVolunteers: 0,
    hoursContributed: 0,
    peopleServed: 0,
    upcomingEvents: 0
  });
  
  const fetchStats = useCallback(async () => {
    try {
      // Get total volunteers (count of unique volunteers in event_volunteers)
      const { data: volunteersData } = await supabase
        .from('event_volunteers')
        .select('user_id', { count: 'exact', head: true })
        .eq('non_profit_id', nonProfitData.id);

      // Get upcoming events count
      const { data: eventsData } = await supabase
        .from('events')
        .select('id', { count: 'exact' })
        .eq('non_profit_id', nonProfitData.id)
        .gte('start_time', new Date().toISOString());

      setStats({
        totalVolunteers: volunteersData?.count || 0,
        hoursContributed: 0,
        peopleServed: 0,
        upcomingEvents: eventsData?.count || 0
      });

    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  }, [nonProfitData]);

  useEffect(() => {
    if (nonProfitData?.id) {
      fetchStats();
    }
  }, [nonProfitData, timeframe, fetchStats]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user || !nonProfitData) {
    return <Navigate to="/signin" />;
  }

  return (
    <div className={styles.dashboard}>
      <div className={styles.header}>
        <img 
          src="/WORDMARK_ALTRUENCE.svg" 
          alt="Altruence" 
          className={styles.logo}
        />
        <div className={styles.headerRight}>
          <button 
            className={styles.createEventButton}
            onClick={() => {/* handle create event */}}
          >
            Create Event
          </button>
          <button 
            className={styles.profileButton}
            onClick={() => {/* handle profile */}}
          >
            {nonProfitData.organization_name}
          </button>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.mainColumn}>
          <div className={styles.calendarSection}>
            <h2 className={styles.sectionTitle}>Calendar Overview</h2>
            <Calendar nonProfitId={nonProfitData.id} />
          </div>

          <div className={styles.summarySection}>
            <div className={styles.sectionHeader}>
              <h2 className={styles.sectionTitle}>Quick Summary</h2>
              <TimeframeSelect 
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
              />
            </div>
            <div className={styles.statsGrid}>
              <div className={styles.statCard}>
                <span className={styles.statValue}>{stats.totalVolunteers}</span>
                <span className={styles.statLabel}>Total Volunteers</span>
              </div>
              <div className={styles.statCard}>
                <span className={styles.statValue}>{stats.hoursContributed}</span>
                <span className={styles.statLabel}>Hours Contributed</span>
              </div>
              <div className={styles.statCard}>
                <span className={styles.statValue}>{stats.peopleServed}</span>
                <span className={styles.statLabel}>People Served</span>
              </div>
              <div className={styles.statCard}>
                <span className={styles.statValue}>{stats.upcomingEvents}</span>
                <span className={styles.statLabel}>Upcoming Events</span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.sideColumn}>
          <MessageNotifications />
        </div>
      </div>
    </div>
  );
};

export default NonProfitDashboard; 