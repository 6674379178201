import React from 'react';
import { Construction } from 'lucide-react';
import styles from './ComingSoonPage.module.css';

const ComingSoonPage = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Construction size={48} className={styles.icon} />
        <h1>Coming Soon!</h1>
        <p>We're building something amazing here.</p>
        <p className={styles.subtitle}>This feature will be available soon.</p>
      </div>
    </div>
  );
};

export default ComingSoonPage; 