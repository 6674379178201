import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { 
  FileText, 
  Download, 
  Eye, 
  File, 
  FileImage, 
  FileCode,
  X,
  Search
} from 'lucide-react';
import styles from './DocumentGrid.module.css';

const FileTypeIcon = ({ fileType }) => {
  switch (fileType) {
    case 'application/pdf':
      return <FileText size={24} className="text-red-500" />;
    case 'image/jpeg':
    case 'image/png':
      return <FileImage size={24} className="text-blue-500" />;
    case 'text/plain':
      return <FileText size={24} className="text-gray-500" />;
    case 'text/code':
      return <FileCode size={24} className="text-green-500" />;
    default:
      return <File size={24} className="text-gray-500" />;
  }
};

const Modal = ({ isOpen, onClose, document, onDownload, formatBytes, formatDate }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <div>
            <h2 className={styles.modalTitle}>{document.title}</h2>
            <p className={styles.modalDescription}>{document.description}</p>
          </div>
          <button 
            onClick={onClose}
            className={styles.closeButton}
          >
            <X size={24} />
          </button>
        </div>
        
        <div className={styles.modalBody}>
          <div className={styles.documentDetails}>
            <div className={styles.detailRow}>
              <FileTypeIcon fileType={document.file_type} />
              <span>{document.file_type}</span>
            </div>
            <div className={styles.detailRow}>
              <span>Size: {formatBytes(document.file_size)}</span>
            </div>
            <div className={styles.detailRow}>
              <span>Uploaded by: {document.uploaded_by?.first_name} {document.uploaded_by?.last_name}</span>
            </div>
            <div className={styles.detailRow}>
              <span>Created: {formatDate(document.created_at)}</span>
            </div>
          </div>

          <div className={styles.previewContainer}>
            {document.file_type.startsWith('image/') ? (
              <img
                src={document.file_url}
                alt={document.title}
                className={styles.previewImage}
              />
            ) : document.file_type === 'application/pdf' ? (
              <iframe
                src={document.file_url}
                title={document.title}
                className={styles.previewFrame}
              />
            ) : (
              <div className={styles.noPreview}>
                <FileText size={48} />
                <p>Preview not available for this file type</p>
              </div>
            )}
          </div>
        </div>

        <div className={styles.modalFooter}>
          <button
            onClick={() => onDownload(document)}
            className={styles.downloadButton}
          >
            <Download size={20} />
            Download
          </button>
        </div>
      </div>
    </div>
  );
};

const DocumentGrid = () => {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState(null);

  const formatBytes = (bytes) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const handlePreview = async (document) => {
    try {
      const { error } = await supabase
        .from('document_access_logs')
        .insert({
          document_id: document.id,
          action: 'view',
          user_agent: navigator.userAgent,
          user_id: supabase.auth.user()?.id
        });

      if (error) throw error;
      setSelectedDocument(document);
    } catch (error) {
      console.error('Error logging document view:', error);
      setSelectedDocument(document);
    }
  };

  const handleDownload = async (document) => {
    try {
      const { error } = await supabase
        .from('document_access_logs')
        .insert({
          document_id: document.id,
          action: 'download',
          user_agent: navigator.userAgent,
          user_id: supabase.auth.user()?.id
        });

      if (error) throw error;

      const link = document.createElement('a');
      link.href = document.file_url;
      link.download = document.file_name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading document:', error);
    }
  };

  const fetchDocuments = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('documents')
        .select(`
          *,
          uploaded_by:uploaded_by_id (
            first_name,
            last_name
          )
        `)
        .eq('status', 'active')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setDocuments(data);
    } catch (err) {
      console.error('Error fetching documents:', err);
      setError('Failed to load documents');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const filteredDocuments = documents.filter(doc => {
    const matchesCategory = selectedCategory === 'all' || doc.category === selectedCategory;
    const matchesSearch = searchTerm === '' || 
      doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      doc.tags?.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()));
    
    return matchesCategory && matchesSearch;
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.pageTitle}>Documents</h1>
        <div className={styles.filterBar}>
          <div className={styles.searchWrapper}>
            <Search className={styles.searchIcon} size={20} />
            <input
              type="text"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className={styles.searchInput}
            />
          </div>
          <div className={styles.filterWrapper}>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className={styles.filterSelect}
            >
              <option value="all">All Categories</option>
              <option value="financial">Financial</option>
              <option value="legal">Legal</option>
              <option value="volunteer">Volunteer</option>
              <option value="event">Event</option>
              <option value="general">General</option>
            </select>
          </div>
        </div>
      </div>

      {loading ? (
        <div className={styles.loadingState}>
          <div className={styles.spinner} />
          <p>Loading documents...</p>
        </div>
      ) : error ? (
        <div className={styles.errorState}>
          <FileText size={48} className={styles.errorIcon} />
          <p className={styles.errorMessage}>{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className={styles.retryButton}
          >
            Try Again
          </button>
        </div>
      ) : filteredDocuments.length === 0 ? (
        <div className={styles.emptyState}>
          <FileText size={48} className={styles.emptyIcon} />
          <p className={styles.emptyMessage}>
            {searchTerm || selectedCategory !== 'all' 
              ? 'No documents match your search criteria'
              : 'No documents found'}
          </p>
        </div>
      ) : (
        <div className={styles.documentList}>
          {filteredDocuments.map((doc) => (
            <div key={doc.id} className={styles.documentCard}>
              <div className={styles.cardContent}>
                <div className={styles.iconHeader}>
                  <FileTypeIcon fileType={doc.file_type} />
                  <div className={styles.actionButtons}>
                    <button
                      onClick={() => handlePreview(doc)}
                      className={styles.actionButton}
                      title="Preview"
                    >
                      <Eye size={20} />
                    </button>
                    <button
                      onClick={() => handleDownload(doc)}
                      className={styles.actionButton}
                      title="Download"
                    >
                      <Download size={20} />
                    </button>
                  </div>
                </div>
                <div className={styles.documentInfo}>
                  <h3 className={styles.documentTitle}>{doc.title}</h3>
                  <div className={styles.documentMeta}>
                    <span>{formatBytes(doc.file_size)}</span>
                    <span className={styles.dot}>•</span>
                    <span>{formatDate(doc.created_at)}</span>
                  </div>
                  {doc.tags && (
                    <div className={styles.tagContainer}>
                      {doc.tags.map((tag, index) => (
                        <span key={index} className={styles.tag}>
                          {tag}
                        </span>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal
        isOpen={!!selectedDocument}
        onClose={() => setSelectedDocument(null)}
        document={selectedDocument}
        onDownload={handleDownload}
        formatBytes={formatBytes}
        formatDate={formatDate}
      />
    </div>
  );
};

export default DocumentGrid;