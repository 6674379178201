import React from 'react';
import styles from './TimeframeSelect.module.css';

const TimeframeSelect = ({ value, onChange }) => {
  return (
    <div className={styles.selectWrapper}>
      <select 
        className={styles.select}
        value={value}
        onChange={onChange}
      >
        <option value="week">This Week</option>
        <option value="month">This Month</option>
        <option value="year">This Year</option>
      </select>
      <img 
        src="/icons/chevron-down.svg" 
        alt="select" 
        className={styles.selectIcon}
      />
    </div>
  );
};

export default TimeframeSelect; 