import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
import { supabase } from '../../supabaseClient';
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from 'lucide-react';
import styles from './EventSelector.module.css';

const EventSelector = ({ onEventSelect, selectedEvent }) => {
  const { nonProfitData } = useAuth();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    if (nonProfitData?.id) {
      fetchEventsByMonth(selectedDate);
    }
  }, [nonProfitData, selectedDate]);

  const fetchEventsByMonth = async (date) => {
    try {
      const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

      const { data, error } = await supabase
        .from('events')
        .select(`
          *,
          event_volunteers (
            id
          )
        `)
        .eq('non_profit_id', nonProfitData.id)
        .gte('start_time', firstDay.toISOString())
        .lte('start_time', lastDay.toISOString())
        .order('start_time', { ascending: true });

      if (error) throw error;

      const formattedEvents = data.map(event => ({
        ...event,
        current_volunteers: event.event_volunteers.length
      }));

      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const changeMonth = (increment) => {
    const newDate = new Date(selectedDate);
    newDate.setMonth(newDate.getMonth() + increment);
    setSelectedDate(newDate);
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long'
    }).format(date);
  };

  return (
    <div className={styles.selector}>
      <div className={styles.dateSelector}>
        <button 
          className={styles.monthNavigator}
          onClick={() => changeMonth(-1)}
        >
          <ChevronLeft size={20} />
        </button>
        <button 
          className={styles.currentMonth}
          onClick={() => setIsCalendarOpen(!isCalendarOpen)}
        >
          <CalendarIcon size={20} />
          <span>{formatDate(selectedDate)}</span>
        </button>
        <button 
          className={styles.monthNavigator}
          onClick={() => changeMonth(1)}
        >
          <ChevronRight size={20} />
        </button>
      </div>

      <div className={styles.eventsList}>
        {events.length === 0 ? (
          <div className={styles.noEvents}>
            No events found for this month
          </div>
        ) : (
          events.map(event => (
            <button
              key={event.id}
              className={`${styles.eventItem} ${
                selectedEvent?.id === event.id ? styles.selected : ''
              }`}
              onClick={() => onEventSelect(event)}
            >
              <div className={styles.eventDate}>
                {new Date(event.start_time).toLocaleDateString('en-US', {
                  weekday: 'short',
                  day: 'numeric'
                })}
              </div>
              <div className={styles.eventInfo}>
                <h3>{event.title}</h3>
                <p>
                  {new Date(event.start_time).toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: '2-digit'
                  })}
                </p>
              </div>
              <div className={styles.volunteerCount}>
                {event.current_volunteers}/{event.max_volunteers}
              </div>
            </button>
          ))
        )}
      </div>
    </div>
  );
};

export default EventSelector; 