import React, { useState } from 'react';
import styles from './NonProfitDetails.module.css';

const NonProfitDetails = ({ onNext, goToStep }) => {
  const [nonProfitData, setNonProfitData] = useState({
    organizationName: '',
    description: '',
    website: '',
    address: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNonProfitData({ ...nonProfitData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(nonProfitData);
  };

  return (
    <div className={styles.detailsPage}>
      <div className={styles.topNavigation}>
        <div className={styles.progressIndicator}>
          <div className={styles.progressStep} onClick={() => goToStep(1)}>
            <div className={styles.stepDone}>1</div>
            <span>Account</span>
          </div>
          <div className={styles.progressLine} />
          <div className={styles.progressStep} onClick={() => goToStep(2)}>
            <div className={styles.stepCurrent}>2</div>
            <span>Organization</span>
          </div>
          <div className={styles.progressLine} />
          <div className={styles.progressStep} onClick={() => goToStep(3)}>
            <div className={styles.stepPending}>3</div>
            <span>Review</span>
          </div>
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Organization Details</h2>
          <p className={styles.subtitle}>Tell us about your non-profit organization</p>
          
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Organization Name</label>
              <input
                type="text"
                name="organizationName"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your organization's name"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Description</label>
              <textarea
                name="description"
                className={`${styles.input} ${styles.textarea}`}
                onChange={handleChange}
                placeholder="Describe your organization's mission and purpose"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Website</label>
              <input
                type="url"
                name="website"
                className={styles.input}
                onChange={handleChange}
                placeholder="https://www.example.org"
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Address</label>
              <input
                type="text"
                name="address"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your organization's address"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Phone Number</label>
              <input
                type="tel"
                name="phone"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your contact number"
                required
              />
            </div>

            <button type="submit" className={styles.submitButton}>
              Next
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NonProfitDetails; 