import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import EventSelector from '../../components/events/EventSelector';
import EventEditForm from '../../components/events/EventEditForm';
import VolunteerApplications from '../../components/events/VolunteerApplications';
import { supabase } from '../../supabaseClient';
import styles from './EventsPages.module.css';

const ManageEventsPage = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchEventFromState = async () => {
      if (location.state?.selectedEventId) {
        try {
          const { data, error } = await supabase
            .from('events')
            .select(`
              *,
              event_volunteers (
                id
              )
            `)
            .eq('id', location.state.selectedEventId)
            .single();

          if (error) throw error;
          
          const eventWithVolunteerCount = {
            ...data,
            current_volunteers: data.event_volunteers.length
          };
          
          setSelectedEvent(eventWithVolunteerCount);
        } catch (error) {
          console.error('Error fetching event:', error);
        }
      }
    };

    fetchEventFromState();
  }, [location.state]);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  const handleEventSave = (updatedEvent) => {
    setSelectedEvent(updatedEvent);
  };

  return (
    <div className={styles.managePage}>
      <h1 className={styles.pageTitle}>Manage Events</h1>
      
      <div className={styles.manageContent}>
        <EventSelector 
          onEventSelect={handleEventSelect}
          selectedEvent={selectedEvent}
        />

        {selectedEvent && (
          <>
            <EventEditForm 
              event={selectedEvent}
              onSave={handleEventSave}
            />
            
            <VolunteerApplications 
              eventId={selectedEvent.id}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ManageEventsPage; 