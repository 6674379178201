import React from 'react';
import DocumentGrid from '../components/documents/DocumentGrid';
import styles from './DocumentsPage.module.css';

const DocumentsPage = () => {
  return (
    <div className={styles.page}>
      <DocumentGrid />
    </div>
  );
};

export default DocumentsPage; 