import React from 'react';
import MessageInbox from '../components/dashboard/MessageInbox';
import styles from './MessagesPage.module.css';

const MessagesPage = () => {
  const handleConversationSelect = (conversation) => {
    // Handle conversation selection
    console.log('Selected conversation:', conversation);
  };

  return (
    <div className={styles.messagesPage}>
      <MessageInbox onConversationSelect={handleConversationSelect} />
    </div>
  );
};

export default MessagesPage; 