import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './EventModal.module.css';

const EventModal = ({ event, onClose }) => {
  const navigate = useNavigate();

  if (!event) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const handleViewDetails = () => {
    navigate('/events/manage', { state: { selectedEventId: event.id } });
    onClose();
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>×</button>
        
        <div className={styles.eventHeader}>
          <h2 className={styles.eventTitle}>{event.title}</h2>
          <span className={styles.eventDate}>{formatDate(event.start_time)}</span>
        </div>

        <div className={styles.eventStats}>
          <div className={styles.statItem}>
            <span className={styles.statLabel}>Volunteers</span>
            <span className={styles.statValue}>
              {event.current_volunteers} / {event.max_volunteers}
            </span>
          </div>
          <div className={styles.statItem}>
            <span className={styles.statLabel}>Location</span>
            <span className={styles.statValue}>{event.location}</span>
          </div>
        </div>

        <div className={styles.eventDescription}>
          <h3>Description</h3>
          <p>{event.description}</p>
        </div>

        <div className={styles.actionButtons}>
          <button 
            className={styles.primaryButton}
            onClick={handleViewDetails}
          >
            View Full Details
          </button>
          <button className={styles.secondaryButton}>
            Share Event
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventModal; 