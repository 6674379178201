import React, { useState } from 'react';
import UserRegistration from './UserRegistration';
import NonProfitDetails from './NonProfitDetails';
import ConfirmationPage from './ConfirmationPage';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import styles from './NonProfitOnboarding.module.css';

const NonProfitOnboarding = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [nonProfitData, setNonProfitData] = useState({});
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const handleUserNext = (data) => {
    setUserData(data);
    setStep(2);
  };

  const handleNonProfitNext = (data) => {
    setNonProfitData(data);
    setStep(3);
  };

  const handleSubmit = async () => {
    try {
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: userData.email,
        password: userData.password,
        options: {
          data: {
            first_name: userData.firstName,
            last_name: userData.lastName,
            is_non_profit: true
          }
        }
      });

      if (signUpError) throw signUpError;
      if (!authData.user) throw new Error('No user data returned after signup');

      const { error: userError } = await supabase.from('Users')
        .insert({
          id: authData.user.id,
          email: userData.email,
          first_name: userData.firstName,
          last_name: userData.lastName,
          is_non_profit: true,
          phone_number: userData.phone,
          password_hash: 'handled_by_supabase_auth'
        });

      if (userError) throw userError;

      const { error: nonProfitError } = await supabase
        .from('non_profits')
        .insert({
          user_id: authData.user.id,
          organization_name: nonProfitData.organizationName,
          description: nonProfitData.description,
          website: nonProfitData.website,
          address: nonProfitData.address,
          phone: nonProfitData.phone
        });

      if (nonProfitError) throw nonProfitError;

      setShowVerificationModal(true);
      setTimeout(() => {
        setShowVerificationModal(false);
        navigate('/signin');
      }, 5000);
    } catch (error) {
      console.error('Error during registration:', error);
      alert('An error occurred during registration.');
    }
  };

  return (
    <div className={styles.onboardingContainer}>
      {step === 1 && <UserRegistration onNext={handleUserNext} goToStep={setStep} />}
      {step === 2 && <NonProfitDetails onNext={handleNonProfitNext} goToStep={setStep} />}
      {step === 3 && <ConfirmationPage userData={userData} nonProfitData={nonProfitData} onSubmit={handleSubmit} />}

      {showVerificationModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <h2>Verification Required</h2>
            <p>You will receive a verification email. Please verify your account to continue.</p>
            <p>Redirecting to sign in page...</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default NonProfitOnboarding; 