import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../supabaseClient';
import { Users, Calendar, Clock, Award, UserCheck, UserX } from 'lucide-react';
import styles from './VolunteerStatsPage.module.css';

const VolunteerStatsPage = () => {
  const [stats, setStats] = useState({
    totalVolunteers: 0,
    activeVolunteers: 0,
    completedEvents: 0,
    averageHours: 0,
    pendingApplications: 0,
    acceptanceRate: 0,
    totalHoursContributed: 0,
    mostActiveVolunteers: []
  });
  const [loading, setLoading] = useState(true);

  const fetchStats = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data: nonProfit } = await supabase
        .from('non_profits')
        .select('id')
        .eq('user_id', user.id)
        .single();

      const { data: volunteers, error } = await supabase
        .from('event_volunteers')
        .select(`
          *,
          Users (
            id,
            first_name,
            last_name
          ),
          events!inner (
            non_profit_id,
            start_time,
            end_time
          )
        `)
        .eq('events.non_profit_id', nonProfit.id);

      if (error) throw error;

      // Calculate enhanced statistics
      const totalVolunteers = new Set(volunteers?.map(v => v.Users.id)).size || 0;
      const activeVolunteers = volunteers?.filter(v => v.status === 'confirmed').length || 0;
      const completedEvents = volunteers?.filter(v => v.status === 'completed').length || 0;
      const pendingApplications = volunteers?.filter(v => v.status === 'pending').length || 0;
      
      // Calculate total hours using start_time and end_time
      const totalHours = volunteers?.reduce((acc, v) => {
        if (v.events.start_time && v.events.end_time) {
          const start = new Date(v.events.start_time);
          const end = new Date(v.events.end_time);
          const hours = (end - start) / (1000 * 60 * 60); // Convert milliseconds to hours
          return acc + hours;
        }
        return acc;
      }, 0) || 0;

      const acceptanceRate = volunteers?.length ? 
        Math.round((activeVolunteers / volunteers.length) * 100) : 0;

      // Get most active volunteers (top 5)
      const volunteerCounts = volunteers?.reduce((acc, v) => {
        const id = v.Users.id;
        acc[id] = acc[id] || { 
          name: `${v.Users.first_name} ${v.Users.last_name}`,
          count: 0 
        };
        acc[id].count++;
        return acc;
      }, {});

      const topVolunteers = Object.values(volunteerCounts || {})
        .sort((a, b) => b.count - a.count)
        .slice(0, 5);

      setStats({
        totalVolunteers,
        activeVolunteers,
        completedEvents,
        averageHours: totalVolunteers ? Math.round(totalHours / totalVolunteers) : 0,
        pendingApplications,
        acceptanceRate,
        totalHoursContributed: Math.round(totalHours),
        mostActiveVolunteers: topVolunteers
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchStats();
  }, [fetchStats]);

  return (
    <div className={styles.page}>
      {loading ? (
        <div className={styles.loading}>Loading...</div>
      ) : (
        <>
          <h1>Volunteer Statistics</h1>
          
          <div className={styles.statsGrid}>
            <div className={styles.statCard}>
              <Users className={styles.statIcon} />
              <h3>Total Volunteers</h3>
              <p className={styles.statNumber}>{stats.totalVolunteers}</p>
            </div>
            <div className={styles.statCard}>
              <UserCheck className={styles.statIcon} />
              <h3>Active Volunteers</h3>
              <p className={styles.statNumber}>{stats.activeVolunteers}</p>
            </div>
            <div className={styles.statCard}>
              <Calendar className={styles.statIcon} />
              <h3>Completed Events</h3>
              <p className={styles.statNumber}>{stats.completedEvents}</p>
            </div>
            <div className={styles.statCard}>
              <Clock className={styles.statIcon} />
              <h3>Total Hours</h3>
              <p className={styles.statNumber}>{stats.totalHoursContributed}</p>
            </div>
            <div className={styles.statCard}>
              <Award className={styles.statIcon} />
              <h3>Acceptance Rate</h3>
              <p className={styles.statNumber}>{stats.acceptanceRate}%</p>
            </div>
            <div className={styles.statCard}>
              <UserX className={styles.statIcon} />
              <h3>Pending Applications</h3>
              <p className={styles.statNumber}>{stats.pendingApplications}</p>
            </div>
          </div>

          <div className={styles.topVolunteersSection}>
            <h2>Most Active Volunteers</h2>
            <div className={styles.topVolunteersGrid}>
              {stats.mostActiveVolunteers.map((volunteer, index) => (
                <div key={index} className={styles.topVolunteerCard}>
                  <span className={styles.rank}>#{index + 1}</span>
                  <p className={styles.volunteerName}>{volunteer.name}</p>
                  <p className={styles.volunteerCount}>{volunteer.count} events</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VolunteerStatsPage; 