import React, { useState, useEffect } from 'react';
import { Send, Mail, MessageSquare, Calendar, UserPlus } from 'lucide-react';
import { supabase } from '../supabaseClient';
import styles from './MailingSystemPage.module.css';

const TEMPLATE_TYPES = {
  THANK_YOU: 'thank_you',
  EVENT_PROMO: 'event_promo',
  REMINDER: 'reminder',
  INVITE: 'invite'
};

const MailingSystemPage = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [recipients, setRecipients] = useState([]);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [recipientForm, setRecipientForm] = useState({
    email: '',
    firstName: '',
    lastName: '',
  });

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data: nonProfit } = await supabase
        .from('non_profits')
        .select('id')
        .eq('user_id', user.id)
        .single();

      const { data: eventsData } = await supabase
        .from('events')
        .select('*')
        .eq('non_profit_id', nonProfit.id)
        .gte('end_time', new Date().toISOString())
        .order('start_time', { ascending: true });

      setEvents(eventsData || []);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleTemplateSelect = (type) => {
    setSelectedTemplate(type);
    if (type === TEMPLATE_TYPES.INVITE && events.length > 0) {
      setSelectedEvent(events[0]);
    }
    const template = getTemplateContent(type);
    setSubject(template.subject);
    setMessage(template.message);
  };

  const getTemplateContent = (type) => {
    switch (type) {
      case TEMPLATE_TYPES.INVITE:
        const event = events.find(e => e.id === selectedEvent?.id) || {};
        return {
          subject: `Join us at ${event.title || '[Event Name]'}`,
          message: `Dear {first_name},\n\nWe would like to invite you to ${event.title || '[Event Name]'} on ${event.start_time ? new Date(event.start_time).toLocaleDateString() : '[Date]'}.\n\n${event.description || ''}\n\nLocation: ${event.location || '[Location]'}\n\nWe hope to see you there!\n\nBest regards,\nThe Altruence Team`
        };
      case TEMPLATE_TYPES.THANK_YOU:
        return {
          subject: 'Thank you for your participation!',
          message: 'Dear {first_name},\n\nThank you for being part of our community. Your contribution made a real difference.\n\nBest regards,\nThe Altruence Team'
        };
      case TEMPLATE_TYPES.EVENT_PROMO:
        return {
          subject: 'Join our upcoming events!',
          message: 'Hello {first_name},\n\nWe have exciting events coming up! Check out our platform for more details.\n\nBest regards,\nThe Altruence Team'
        };
      case TEMPLATE_TYPES.REMINDER:
        return {
          subject: 'Reminder: Upcoming volunteer opportunity',
          message: 'Hi {first_name},\n\nThis is a friendly reminder about upcoming volunteer opportunities.\n\nBest regards,\nThe Altruence Team'
        };
      default:
        return { subject: '', message: '' };
    }
  };

  const handleRecipientFormChange = (e) => {
    const { name, value } = e.target;
    setRecipientForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleAddRecipient = (e) => {
    e.preventDefault();
    if (recipientForm.email && recipientForm.email.includes('@')) {
      setRecipients([...recipients, {
        email: recipientForm.email,
        first_name: recipientForm.firstName || 'Volunteer',
        last_name: recipientForm.lastName || ''
      }]);
      setRecipientForm({
        email: '',
        firstName: '',
        lastName: ''
      });
    }
  };

  const handleRemoveEmail = (email) => {
    setRecipients(recipients.filter(r => r.email !== email));
  };

  const handleSend = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://vvenhzsvyfxywxdovvma.supabase.co/functions/v1/send-email',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            recipients,
            subject,
            message,
          }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.details || 'Failed to send emails');
      }

      setMessage('');
      setSubject('');
      setRecipients([]);
      setSelectedTemplate(null);
      setSelectedEvent(null);
      setRecipientForm({
        email: '',
        firstName: '',
        lastName: ''
      });
    } catch (error) {
      console.error('Error sending messages:', error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.header}>
        <h1 className={styles.title}>Mailing System</h1>
      </div>

      <div className={styles.content}>
        <div className={styles.templateSection}>
          <h2 className={styles.sectionTitle}>Select Template</h2>
          <div className={styles.templateGrid}>
            <button
              className={`${styles.templateCard} ${selectedTemplate === TEMPLATE_TYPES.THANK_YOU ? styles.active : ''}`}
              onClick={() => handleTemplateSelect(TEMPLATE_TYPES.THANK_YOU)}
            >
              <Mail size={24} />
              <h3>Thank You Message</h3>
              <p>Send appreciation to event participants</p>
            </button>
            <button
              className={`${styles.templateCard} ${selectedTemplate === TEMPLATE_TYPES.EVENT_PROMO ? styles.active : ''}`}
              onClick={() => handleTemplateSelect(TEMPLATE_TYPES.EVENT_PROMO)}
            >
              <Calendar size={24} />
              <h3>Event Promotion</h3>
              <p>Promote upcoming events</p>
            </button>
            <button
              className={`${styles.templateCard} ${selectedTemplate === TEMPLATE_TYPES.REMINDER ? styles.active : ''}`}
              onClick={() => handleTemplateSelect(TEMPLATE_TYPES.REMINDER)}
            >
              <MessageSquare size={24} />
              <h3>Event Reminder</h3>
              <p>Send reminders to registered participants</p>
            </button>
            <button
              className={`${styles.templateCard} ${selectedTemplate === TEMPLATE_TYPES.INVITE ? styles.active : ''}`}
              onClick={() => handleTemplateSelect(TEMPLATE_TYPES.INVITE)}
            >
              <UserPlus size={24} />
              <h3>Invite to Event</h3>
              <p>Send event invitations</p>
            </button>
          </div>
        </div>

        {selectedTemplate === TEMPLATE_TYPES.INVITE && (
          <div className={styles.eventSection}>
            <h2 className={styles.sectionTitle}>Select Event</h2>
            <select
              value={selectedEvent?.id || ''}
              onChange={(e) => {
                const event = events.find(ev => ev.id === e.target.value);
                setSelectedEvent(event);
                const template = getTemplateContent(TEMPLATE_TYPES.INVITE);
                setSubject(template.subject);
                setMessage(template.message);
              }}
              className={styles.eventSelect}
            >
              {events.map(event => (
                <option key={event.id} value={event.id}>
                  {event.title} - {new Date(event.start_time).toLocaleDateString()}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className={styles.recipientsSection}>
          <h2 className={styles.sectionTitle}>Add Recipients</h2>
          <form onSubmit={handleAddRecipient} className={styles.recipientForm}>
            <div className={styles.formGroup}>
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                id="email"
                name="email"
                value={recipientForm.email}
                onChange={handleRecipientFormChange}
                required
                className={styles.formInput}
              />
            </div>
            <div className={styles.formRow}>
              <div className={styles.formGroup}>
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={recipientForm.firstName}
                  onChange={handleRecipientFormChange}
                  className={styles.formInput}
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={recipientForm.lastName}
                  onChange={handleRecipientFormChange}
                  className={styles.formInput}
                />
              </div>
            </div>
            <button type="submit" className={styles.addButton}>
              Add Recipient
            </button>
          </form>

          <div className={styles.recipientsGrid}>
            {recipients.map((recipient) => (
              <div key={recipient.email} className={styles.recipientCard}>
                <span>{recipient.first_name}</span>
                <span className={styles.recipientEmail}>{recipient.email}</span>
                <button 
                  onClick={() => handleRemoveEmail(recipient.email)}
                  className={styles.removeButton}
                >
                  ×
                </button>
              </div>
            ))}
          </div>
        </div>

        {recipients.length > 0 && (
          <div className={styles.messageSection}>
            <h2 className={styles.sectionTitle}>Message</h2>
            <input
              type="text"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              className={styles.subjectInput}
            />
            <textarea
              placeholder="Type your message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={styles.messageInput}
            />
            <button 
              className={styles.sendButton}
              disabled={loading || !message || !subject}
              onClick={handleSend}
            >
              <Send size={20} />
              Send Emails
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MailingSystemPage; 