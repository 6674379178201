import React, { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import styles from './DashboardLayout.module.css';
import MessageInbox from './MessageInbox';
import Sidebar from './Sidebar';

const DashboardLayout = () => {
  const [showMessages, setShowMessages] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (path) => {
    if (path === '/dashboard/chats') {
      setShowMessages(!showMessages);
    } else if (path === '/dashboard') {
      setShowMessages(false);
      navigate('/nonprofit-dashboard');
    }
  };

  return (
    <div className={styles.dashboardLayout}>
      <Sidebar onNavClick={handleNavClick} activeItem={showMessages ? 'Chats' : ''} />
      <div className={styles.mainContent}>
        {showMessages ? (
          <div className={styles.messageInboxWrapper}>
            <MessageInbox />
          </div>
        ) : (
          <>
            <header className={styles.header}>
              <h1>Excelsior Community Food Pantry</h1>
              <div className={styles.userMenu}>
              </div>
            </header>
            <main className={styles.content}>
              <Outlet />
            </main>
          </>
        )}
        <button className={styles.chatbotButton}>
          <span>Need Help?</span>
        </button>
      </div>
    </div>
  );
};

export default DashboardLayout; 