import React, { useState, useEffect, useCallback } from 'react';
import { supabase } from '../../supabaseClient';
import { 
  Loader2, 
  CheckCircle, 
  XCircle, 
  AlertCircle, 
  Search, 
  Filter,
  Clock,
  Ban,
  UserCircle
} from 'lucide-react';
import VolunteerDetailsModal from './VolunteerDetailsModal';
import styles from './VolunteerApplications.module.css';

const STATUS_LABELS = {
  pending: { label: 'Pending Review', icon: Clock, color: 'statusPending' },
  accepted: { label: 'Accepted', icon: CheckCircle, color: 'statusAccepted' },
  denied: { label: 'Denied', icon: XCircle, color: 'statusDenied' },
  confirmed: { label: 'Confirmed', icon: CheckCircle, color: 'statusConfirmed' },
  cancelled: { label: 'Cancelled', icon: Ban, color: 'statusCancelled' }
};

const VolunteerApplications = ({ eventId }) => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [selectedVolunteer, setSelectedVolunteer] = useState(null);

  const fetchApplications = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('event_volunteers')
        .select(`
          id,
          status,
          created_at,
          note,
          Users (
            id,
            first_name,
            last_name,
            email,
            phone_number,
            profile_image
          )
        `)
        .eq('event_id', eventId);

      if (error) throw error;
      setApplications(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching applications:', error);
      setError('Failed to load applications');
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      fetchApplications();
    }
  }, [eventId, fetchApplications]);

  const handleStatusUpdate = async (applicationId, newStatus) => {
    setApplications(prev =>
      prev.map(app =>
        app.id === applicationId ? { ...app, status: newStatus } : app
      )
    );

    try {
      const { error } = await supabase
        .from('event_volunteers')
        .update({ 
          status: newStatus,
          updated_at: new Date().toISOString()
        })
        .eq('id', applicationId);

      if (error) throw error;
    } catch (error) {
      console.error('Error updating application:', error);
    }
  };

  const filteredApplications = applications.filter(app => {
    const matchesSearch = searchTerm === '' || 
      `${app.Users.first_name} ${app.Users.last_name}`.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesStatus = selectedStatus === 'all' || app.status === selectedStatus;
    return matchesSearch && matchesStatus;
  });

  return (
    <div className={styles.volunteerApplications}>
      <div className={styles.filters}>
        <div className={styles.searchBox}>
          <Search size={20} />
          <input
            type="text"
            placeholder="Search volunteers..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className={styles.statusFilter}>
          <Filter size={20} />
          <select
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            {Object.entries(STATUS_LABELS).map(([value, { label }]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
      </div>

      {loading ? (
        <div className={styles.loader}>
          <Loader2 size={48} />
        </div>
      ) : error ? (
        <div className={styles.error}>
          <AlertCircle size={24} />
          <span>{error}</span>
        </div>
      ) : (
        <div className={styles.scrollContainer}>
          <div className={styles.volunteerList}>
            {filteredApplications.map(app => {
              const IconComponent = STATUS_LABELS[app.status].icon;
              return (
                <div 
                  key={app.id} 
                  className={styles.container}
                  onClick={() => setSelectedVolunteer(app)}
                >
                  <div className={styles.contentContainer}>
                    <div className={styles.profileCard}>
                      <div className={styles.profileInfo}>
                        <div className={styles.avatar}>
                          <UserCircle size={42} />
                        </div>
                        <div className={styles.userDetails}>
                          <div className={styles.userName}>
                            {app.Users.first_name} {app.Users.last_name}
                          </div>
                          <div className={styles.userActivity}>
                            <div className={styles.activityInfo}>
                              <IconComponent size={16} />
                              <span>{app.status === 'accepted' ? 'Accepted Volunteer' : 'Pending Volunteer'}</span>
                            </div>
                            <div className={styles.activityInfo}>
                              <Clock size={16} />
                              <span>Applied: {new Date(app.created_at).toLocaleDateString()}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.actionsContainer}>
                      <button
                        className={`${styles.actionButton} ${styles[app.status]}`}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedVolunteer(app);
                        }}
                      >
                        {IconComponent && <IconComponent size={20} />}
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {selectedVolunteer && (
        <VolunteerDetailsModal
          volunteer={selectedVolunteer}
          onClose={() => setSelectedVolunteer(null)}
          onStatusUpdate={handleStatusUpdate}
        />
      )}
    </div>
  );
};

export default VolunteerApplications; 