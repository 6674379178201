import React, { useState } from 'react';
import styles from './VolunteerRegistration.module.css';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const VolunteerRegistration = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email: userData.email,
        password: userData.password,
        options: {
          data: {
            first_name: userData.firstName,
            last_name: userData.lastName,
            is_non_profit: false
          }
        }
      });

      if (signUpError) throw signUpError;
      if (!authData.user) throw new Error('No user data returned after signup');

      const { error: userError } = await supabase.from('Users')
        .insert({
          id: authData.user.id,
          email: userData.email,
          first_name: userData.firstName,
          last_name: userData.lastName,
          is_volunteer: true,
          phone_number: userData.phone,
          password_hash: 'handled_by_supabase_auth'
        });

      if (userError) throw userError;

      alert('Registration successful! Please check your email to verify your account.');
      navigate('/signin');
    } catch (error) {
      console.error('Error during registration:', error);
      alert('An error occurred during registration.');
    }
  };

  return (
    <div className={styles.registrationPage}>
      <div className={styles.topNavigation}>
        <button className={styles.backButton} onClick={() => navigate('/')}>
          <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10L2 6L6 2" stroke="black" strokeWidth="2" strokeLinecap="round"/>
          </svg>
        </button>
        <img 
          className={styles.logo} 
          src="/WORDMARK_ALTRUENCE.svg" 
          alt="Altruence" 
        />
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Create Your Volunteer Account</h2>
          <p className={styles.subtitle}>Join our community and start making a difference</p>
          
          <form onSubmit={handleSubmit}>
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>First Name</label>
              <input
                type="text"
                name="firstName"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your first name"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Last Name</label>
              <input
                type="text"
                name="lastName"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your last name"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Email</label>
              <input
                type="email"
                name="email"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Phone Number</label>
              <input
                type="tel"
                name="phone"
                className={styles.input}
                onChange={handleChange}
                placeholder="Enter your phone number"
                required
              />
            </div>

            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Password</label>
              <input
                type="password"
                name="password"
                className={styles.input}
                onChange={handleChange}
                placeholder="Create a password"
                required
              />
            </div>

            <button type="submit" className={styles.submitButton}>
              Create Account
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default VolunteerRegistration; 