import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useAuth } from '../context/AuthContext';
import Calendar from '../components/dashboard/Calendar';
import EventModal from '../components/dashboard/EventModal';
import styles from './EventsPage.module.css';

const EventsPage = () => {
  const { nonProfitData } = useAuth();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDayEvents, setSelectedDayEvents] = useState([]);

  const handleDayClick = async (date) => {
    setSelectedDate(date);
    try {
      const { data, error } = await supabase
        .from('events')
        .select(`
          *,
          event_volunteers (
            id
          )
        `)
        .eq('non_profit_id', nonProfitData.id)
        .gte('start_time', new Date(date.setHours(0,0,0,0)).toISOString())
        .lt('start_time', new Date(date.setHours(23,59,59,999)).toISOString());

      if (error) throw error;

      const eventsWithVolunteerCount = data.map(event => ({
        ...event,
        current_volunteers: event.event_volunteers.length
      }));

      setSelectedDayEvents(eventsWithVolunteerCount);
      
      // If there's only one event, show it immediately
      if (eventsWithVolunteerCount.length === 1) {
        setSelectedEvent(eventsWithVolunteerCount[0]);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  return (
    <div className={styles.eventsPage}>
      <div className={styles.calendarSection}>
        <Calendar 
          nonProfitId={nonProfitData?.id} 
          onDayClick={handleDayClick}
        />
      </div>
      
      {selectedDate && selectedDayEvents.length > 1 && (
        <div className={styles.eventsList}>
          <h2 className={styles.eventsHeader}>
            Events for {selectedDate.toLocaleDateString()}
          </h2>
          {selectedDayEvents.map(event => (
            <div 
              key={event.id} 
              className={styles.eventCard}
              onClick={() => setSelectedEvent(event)}
            >
              <h3 className={styles.eventTitle}>{event.title}</h3>
              <p className={styles.eventTime}>
                {new Date(event.start_time).toLocaleTimeString()}
              </p>
            </div>
          ))}
        </div>
      )}

      {selectedEvent && (
        <EventModal 
          event={selectedEvent} 
          onClose={() => setSelectedEvent(null)}
        />
      )}
    </div>
  );
};

export default EventsPage; 