import { Link, useNavigate } from 'react-router-dom';
import { Home, Calendar, MessageSquare, FileText, ChevronDown, ChevronRight, Mail, Users } from 'lucide-react';
import { useState } from 'react';
import styles from './Sidebar.module.css';

const menuItems = [
  { name: 'Home', icon: Home, href: '/nonprofit-dashboard' },
  { 
    name: 'Events', 
    icon: Calendar, 
    href: '/events',
    subItems: [
      { name: 'General Overview', href: '/events' },
      { name: 'Manage Events', href: '/events/manage' },
      { name: 'Event Stats', href: '/events/stats' },
      { name: 'Event Activity', href: '/events/activity' },
      { name: 'Event Templates', href: '/events/templates' },
    ]
  },
  {
    name: 'Volunteers',
    icon: Users,
    href: '/volunteers',
    subItems: [
      { name: 'Volunteers List', href: '/volunteers/list' },
      { name: 'Applications', href: '/volunteers/applications' },
      { name: 'Statistics', href: '/volunteers/stats' },
    ]
  },
  { name: 'Chats', icon: MessageSquare, href: '/messages' },
  { name: 'Documents', icon: FileText, href: '/dashboard/documents' },
  { name: 'Mailing', icon: Mail, href: '/mailing' },
];

const Sidebar = ({ onNavClick, activeItem }) => {
  const [expandedItem, setExpandedItem] = useState(null);
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    if (item.subItems) {
      setExpandedItem(expandedItem === item.name ? null : item.name);
    } else {
      onNavClick?.(item.href);
      navigate(item.href);
    }
  };

  return (
    <div className={styles.sidebar}>
      {menuItems.map((item) => (
        <div key={item.name} className={styles.menuItem}>
          <button
            className={`${styles.menuButton} ${activeItem === item.name ? styles.active : ''}`}
            onClick={() => handleItemClick(item)}
          >
            <div className={styles.menuButtonContent}>
              <item.icon className={styles.icon} />
              <span className={styles.linkText}>{item.name}</span>
              {item.subItems && (
                expandedItem === item.name ? 
                  <ChevronDown className={styles.chevron} /> : 
                  <ChevronRight className={styles.chevron} />
              )}
            </div>
          </button>
          
          {item.subItems && expandedItem === item.name && (
            <div className={styles.subMenu}>
              {item.subItems.map((subItem) => (
                subItem.disabled ? (
                  <span key={subItem.href} className={`${styles.subMenuItem} ${styles.disabled}`}>
                    {subItem.name}
                  </span>
                ) : (
                  <Link
                    key={subItem.href}
                    to={subItem.href}
                    className={styles.subMenuItem}
                    onClick={() => navigate(subItem.href)}
                  >
                    {subItem.name}
                  </Link>
                )
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;

