import React from 'react';
import styles from './ConfirmationPage.module.css';

const ConfirmationPage = ({ userData, nonProfitData, onSubmit, goToStep }) => {
  return (
    <div className={styles.confirmationPage}>
      <div className={styles.topNavigation}>
        <div className={styles.progressIndicator}>
          <div className={styles.progressStep} onClick={() => goToStep(1)}>
            <div className={styles.stepDone}>1</div>
            <span>Account</span>
          </div>
          <div className={styles.progressLine} />
          <div className={styles.progressStep} onClick={() => goToStep(2)}>
            <div className={styles.stepDone}>2</div>
            <span>Organization</span>
          </div>
          <div className={styles.progressLine} />
          <div className={styles.progressStep} onClick={() => goToStep(3)}>
            <div className={styles.stepCurrent}>3</div>
            <span>Review</span>
          </div>
        </div>
      </div>

      <div className={styles.contentWrapper}>
        <div className={styles.formContainer}>
          <h2 className={styles.title}>Review Your Details</h2>
          <p className={styles.subtitle}>Please confirm the information below before submitting</p>

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>User Information</h3>
            <p><strong>First Name:</strong> {userData.firstName}</p>
            <p><strong>Last Name:</strong> {userData.lastName}</p>
            <p><strong>Email:</strong> {userData.email}</p>
          </div>

          <div className={styles.section}>
            <h3 className={styles.sectionTitle}>Organization Information</h3>
            <p><strong>Organization Name:</strong> {nonProfitData.organizationName}</p>
            <p><strong>Description:</strong> {nonProfitData.description}</p>
            <p><strong>Website:</strong> {nonProfitData.website}</p>
            <p><strong>Address:</strong> {nonProfitData.address}</p>
            <p><strong>Phone:</strong> {nonProfitData.phone}</p>
          </div>

          <button onClick={onSubmit} className={styles.submitButton}>
            Confirm and Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPage; 