import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { supabase } from '../../supabaseClient';
import EventSelector from '../../components/events/EventSelector';
import styles from './EventsPages.module.css';

const EventStatsPage = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [volunteerStats, setVolunteerStats] = useState({
    totalApplications: 0,
    acceptedVolunteers: 0,
    pendingVolunteers: 0,
    deniedVolunteers: 0,
    statusDistribution: []
  });

  useEffect(() => {
    const fetchEventStats = async () => {
      if (!selectedEvent?.id) return;

      try {
        const { data, error } = await supabase
          .from('event_volunteers')
          .select('status')
          .eq('event_id', selectedEvent.id);

        if (error) throw error;

        // Calculate statistics
        const stats = {
          totalApplications: data.length,
          acceptedVolunteers: data.filter(v => v.status === 'accepted' || v.status === 'confirmed').length,
          pendingVolunteers: data.filter(v => v.status === 'pending').length,
          deniedVolunteers: data.filter(v => v.status === 'denied').length,
        };

        // Create distribution data for chart
        const distribution = [
          { status: 'Pending', count: stats.pendingVolunteers },
          { status: 'Accepted', count: stats.acceptedVolunteers },
          { status: 'Denied', count: stats.deniedVolunteers },
        ];

        setVolunteerStats({
          ...stats,
          statusDistribution: distribution
        });

      } catch (error) {
        console.error('Error fetching event stats:', error);
      }
    };

    fetchEventStats();
  }, [selectedEvent]);

  const handleEventSelect = (event) => {
    setSelectedEvent(event);
  };

  return (
    <div className={styles.page}>
      <h1 className={styles.pageTitle}>Event Statistics</h1>
      
      <div className={styles.statsContent}>
        <div className={styles.selectorColumn}>
          <EventSelector 
            onEventSelect={handleEventSelect}
            selectedEvent={selectedEvent}
          />
        </div>

        {selectedEvent ? (
          <div className={styles.statsColumn}>
            <div className={styles.eventHeader}>
              <h2>{selectedEvent.title}</h2>
              <p className={styles.eventDate}>
                {new Date(selectedEvent.start_time).toLocaleDateString()}
              </p>
            </div>

            <div className={styles.statsGrid}>
              <div className={styles.statCard}>
                <span className={styles.statValue}>{volunteerStats.totalApplications}</span>
                <span className={styles.statLabel}>Total Applications</span>
              </div>
              <div className={styles.statCard}>
                <span className={styles.statValue}>
                  {selectedEvent.current_volunteers} / {selectedEvent.max_volunteers}
                </span>
                <span className={styles.statLabel}>Current Volunteers</span>
              </div>
              <div className={styles.statCard}>
                <span className={styles.statValue}>{volunteerStats.pendingVolunteers}</span>
                <span className={styles.statLabel}>Pending Review</span>
              </div>
              <div className={styles.statCard}>
                <span className={styles.statValue}>
                  {Math.round((volunteerStats.acceptedVolunteers / volunteerStats.totalApplications) * 100 || 0)}%
                </span>
                <span className={styles.statLabel}>Acceptance Rate</span>
              </div>
            </div>

            <div className={styles.chartSection}>
              <h3>Volunteer Status Distribution</h3>
              <div className={styles.chart}>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart data={volunteerStats.statusDistribution}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="status" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="count" fill="rgba(223, 255, 71, 0.8)" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.noEventSelected}>
            <p>Select an event to view its statistics</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventStatsPage; 