import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { Calendar, Clock, MapPin, Users, FileText } from 'lucide-react';
import styles from './EventEditForm.module.css';

const EventEditForm = ({ event, onSave, onCancel }) => {
  const [formData, setFormData] = useState(event);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    if (event) {
      setFormData({
        title: event.title || '',
        description: event.description || '',
        start_time: formatDateTime(event.start_time) || '',
        end_time: formatDateTime(event.end_time) || '',
        location: event.location || '',
        max_volunteers: event.max_volunteers || 1,
      });
    }
  }, [event]);

  const formatDateTime = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().slice(0, 16); // Format: "2024-03-21T14:30"
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowConfirmModal(true);
  };

  const handleConfirmSave = () => {
    onSave(formData);
    setShowConfirmModal(false);
  };

  const handleCancelSave = () => {
    setShowConfirmModal(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h2 className={styles.formTitle}>Edit Event Details</h2>
        
        <div className={styles.formGrid}>
          <div className={styles.formField}>
            <label>
              <FileText size={16} />
              Event Title
            </label>
            <input
              type="text"
              value={formData.title}
              onChange={(e) => setFormData({...formData, title: e.target.value})}
              required
            />
          </div>

          <div className={styles.formField}>
            <label>
              <Calendar size={16} />
              Start Date & Time
            </label>
            <input
              type="datetime-local"
              value={formData.start_time}
              onChange={(e) => setFormData({...formData, start_time: e.target.value})}
              required
            />
          </div>

          <div className={styles.formField}>
            <label>
              <Clock size={16} />
              End Date & Time
            </label>
            <input
              type="datetime-local"
              value={formData.end_time}
              onChange={(e) => setFormData({...formData, end_time: e.target.value})}
              required
            />
          </div>

          <div className={styles.formField}>
            <label>
              <MapPin size={16} />
              Location
            </label>
            <input
              type="text"
              value={formData.location}
              onChange={(e) => setFormData({...formData, location: e.target.value})}
              required
            />
          </div>

          <div className={styles.formField}>
            <label>
              <Users size={16} />
              Maximum Volunteers
            </label>
            <input
              type="number"
              min="1"
              value={formData.max_volunteers}
              onChange={(e) => setFormData({...formData, max_volunteers: parseInt(e.target.value)})}
              required
            />
          </div>

          <div className={`${styles.formField} ${styles.fullWidth}`}>
            <label>
              <FileText size={16} />
              Description
            </label>
            <textarea
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              rows={4}
              required
            />
          </div>
        </div>

        <div className={styles.formActions}>
          <button type="submit" className={styles.saveButton}>
            Save Changes
          </button>
        </div>
      </form>

      {showConfirmModal && (
        <div className={styles.confirmModalOverlay}>
          <div className={styles.confirmModal}>
            <h3 className={styles.confirmTitle}>Save Changes?</h3>
            <p>Are you sure you want to save these changes?</p>
            <div className={styles.confirmButtons}>
              <button 
                className={styles.confirmButton}
                onClick={handleConfirmSave}
              >
                Save Changes
              </button>
              <button 
                className={styles.cancelButton}
                onClick={handleCancelSave}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventEditForm; 