import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './HomePage.module.css';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.homePage}>
      <img 
        className={styles.logo} 
        src="/WORDMARK_ALTRUENCE.svg"
        alt="Altruence" 
      />

      <div className={styles.cardContainer}>
        <div className={styles.card}>
          <div className={styles.cardContent}>
            <h2 className={styles.cardTitle}>For Non-Profits</h2>
            <p className={styles.cardDescription}>Create and manage volunteer events</p>
            <div className={styles.buttonGroup}>
              <button 
                className={`${styles.button} ${styles.signUpButton}`}
                onClick={() => navigate('/nonprofit-onboarding')}
              >
                Sign Up
              </button>
              <button 
                className={`${styles.button} ${styles.signInButton}`}
                onClick={() => navigate('/signin')}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardContent}>
            <h2 className={styles.cardTitle}>For Volunteers</h2>
            <p className={styles.cardDescription}>Find and join volunteer opportunities</p>
            <div className={styles.buttonGroup}>
              <button 
                className={`${styles.button} ${styles.signUpButton}`}
                onClick={() => navigate('/volunteer-signup')}
              >
                Sign Up
              </button>
              <button 
                className={`${styles.button} ${styles.signInButton}`}
                onClick={() => navigate('/signin')}
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage; 