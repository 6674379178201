import React, { useState } from 'react';
import { 
  X, 
  Clock, 
  CheckCircle, 
  XCircle, 
  Ban,
  UserCircle 
} from 'lucide-react';
import styles from './VolunteerDetailsModal.module.css';
import { supabase } from '../../supabaseClient';

const VolunteerDetailsModal = ({ volunteer, onClose, onStatusUpdate }) => {
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const STATUS_OPTIONS = [
    { id: 'pending', label: 'Pending Review', icon: Clock },
    { id: 'accepted', label: 'Accepted', icon: CheckCircle },
    { id: 'denied', label: 'Denied', icon: XCircle },
    { id: 'confirmed', label: 'Confirmed', icon: CheckCircle },
    { id: 'cancelled', label: 'Cancelled', icon: Ban }
  ];

  const handleStatusClick = (statusId) => {
    setSelectedStatus(statusId);
  };

  const handleConfirmStatus = async () => {
    if (selectedStatus) {
      try {
        const { error } = await supabase
          .from('event_volunteers')
          .update({ 
            status: selectedStatus,
            updated_at: new Date().toISOString()
          })
          .eq('id', volunteer.id);

        if (error) throw error;
        
        onStatusUpdate(volunteer.id, selectedStatus);
        setShowStatusOptions(false);
        setSelectedStatus(null);
      } catch (error) {
        console.error('Error updating status:', error);
      }
    }
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modal} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          <X size={24} />
        </button>
        
        <div className={styles.content}>
          <div className={styles.header}>
            <div className={styles.avatar}>
              <UserCircle size={64} />
            </div>
            <div className={styles.headerInfo}>
              <h2>{volunteer.Users.first_name} {volunteer.Users.last_name}</h2>
              <span className={styles.currentStatus}>
                {STATUS_OPTIONS.find(s => s.id === volunteer.status)?.label}
              </span>
            </div>
          </div>

          <div className={styles.details}>
            <div className={styles.detailGroup}>
              <h3>Contact Information</h3>
              <p>Email: {volunteer.Users.email}</p>
              <p>Phone: {volunteer.Users.phone_number}</p>
            </div>

            <div className={styles.detailGroup}>
              <h3>Application Details</h3>
              <p>Applied: {new Date(volunteer.created_at).toLocaleDateString()}</p>
              {volunteer.note && <p>Note: {volunteer.note}</p>}
            </div>

            <div className={styles.statusToggleContainer}>
              <button 
                className={`${styles.toggleButton} ${showStatusOptions ? styles.active : ''}`}
                onClick={() => setShowStatusOptions(!showStatusOptions)}
              >
                <div className={styles.filterForYou}>Assign Status</div>
              </button>
              
              {showStatusOptions && (
                <div className={styles.statusOptions}>
                  {STATUS_OPTIONS.map(({ id, label, icon: Icon }) => (
                    <button
                      key={id}
                      className={`${styles.toggleButton} 
                        ${selectedStatus === id ? styles.active : ''} 
                        ${volunteer.status === id ? styles.current : ''}
                      `}
                      onClick={() => handleStatusClick(id)}
                    >
                      <div className={styles.filterForYou}>
                        <Icon size={14} className={styles.icon} />
                        <span>{label}</span>
                      </div>
                    </button>
                  ))}
                  {selectedStatus && (
                    <button 
                      className={styles.supportiveButton}
                      onClick={handleConfirmStatus}
                    >
                      <CheckCircle size={11} className={styles.vectorIcon} />
                      <div className={styles.viewImpactText}>Confirm Change</div>
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VolunteerDetailsModal; 