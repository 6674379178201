// src/App.tsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NonProfitDashboard from './pages/NonProfitDashboard';
import NonProfitOnboarding from './components/NonProfitOnboarding';
import VolunteerRegistration from './components/VolunteerRegistration';
import SignIn from './components/SignIn';
import HomePage from './pages/HomePage';
import DashboardLayout from './components/dashboard/DashboardLayout';
import { AuthProvider } from './context/AuthContext';
import EventsPage from './pages/EventsPage';
import ManageEventsPage from './pages/events/ManageEventsPage';
import EventStatsPage from './pages/events/EventStatsPage';
import EventTemplatesPage from './pages/events/EventTemplatesPage';
import MessagesPage from './pages/MessagesPage';
import DocumentsPage from './pages/DocumentsPage';
import MailingSystemPage from './pages/MailingSystemPage';
import VolunteersListPage from './pages/VolunteersListPage';
import VolunteerApplicationsPage from './pages/volunteers/VolunteerApplicationsPage';
import VolunteerStatsPage from './pages/volunteers/VolunteerStatsPage';
import ComingSoonPage from './components/ComingSoonPage';

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/nonprofit-onboarding" element={<NonProfitOnboarding />} />
          <Route path="/volunteer-signup" element={<VolunteerRegistration />} />
          <Route element={<DashboardLayout />}>
            <Route path="/nonprofit-dashboard" element={<NonProfitDashboard />} />
            <Route path="/messages" element={<MessagesPage />} />
            <Route path="/documents" element={<DocumentsPage />} />
            <Route path="/dashboard/documents" element={<DocumentsPage />} />
            <Route path="/mailing" element={<MailingSystemPage />} />
            <Route path="/events">
              <Route index element={<EventsPage />} />
              <Route path="manage" element={<ManageEventsPage />} />
              <Route path="stats" element={<EventStatsPage />} />
              <Route path="templates" element={<EventTemplatesPage />} />
              <Route path="activity" element={<ComingSoonPage />} />
            </Route>
            <Route path="/volunteers">
              <Route path="list" element={<VolunteersListPage />} />
              <Route path="applications" element={<VolunteerApplicationsPage />} />
              <Route path="stats" element={<VolunteerStatsPage />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;