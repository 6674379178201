import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import styles from './MessageNotifications.module.css';

const MessageNotifications = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const fetchNotifications = async () => {
      const { data: userData } = await supabase.auth.getUser();
      if (!userData?.user?.id) return;

      const { data, error } = await supabase
        .from('messages')
        .select(`
          id,
          content,
          created_at,
          sender_id,
          message_type,
          conversations!inner (
            id,
            type,
            title
          ),
          Users!messages_sender_id_fkey (
            first_name,
            last_name
          )
        `)
        .eq('message_status.read_at', null)
        .eq('message_status.user_id', userData.user.id)
        .order('created_at', { ascending: false })
        .limit(5);

      if (error) {
        console.error('Error fetching notifications:', error);
        return;
      }

      setNotifications(data);
    };

    fetchNotifications();

    // Subscribe to new messages
    const subscription = supabase
      .channel('public:messages')
      .on('postgres_changes', 
        { 
          event: 'INSERT', 
          schema: 'public', 
          table: 'messages' 
        }, 
        () => {
          fetchNotifications();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div className={styles.notificationsContainer}>
      <h3 className={styles.notificationsTitle}>Recent Messages</h3>
      <div className={styles.notificationsList}>
        {notifications.map((notification) => (
          <div key={notification.id} className={styles.notificationItem}>
            <div className={styles.notificationContent}>
              <span className={styles.senderName}>
                {notification.Users.first_name} {notification.Users.last_name}
              </span>
              <p className={styles.messagePreview}>
                {notification.content.length > 50 
                  ? `${notification.content.substring(0, 50)}...` 
                  : notification.content}
              </p>
            </div>
            <span className={styles.notificationTime}>
              {new Date(notification.created_at).toLocaleTimeString()}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MessageNotifications; 